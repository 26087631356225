<template>
	<div class="o">
		<div class="o-title">
			<div class="o-t-left">总报价数量<span>{{data.length}}</span>个</div>
			<!-- <div class="m-r-cart" @click="showPurchase">
				<div class="c-cart-icon">
					<i class="iconfont icon-gouwuche"></i>
					<div class="dot" v-if="cartGoods.length">
						<p>{{cartGoods.length}}</p>
					</div>
				</div>
			</div> -->
		</div>
		<el-collapse>
		      <el-collapse-item :name="index" v-for="(item,index) in data" :key="index">
		        <template #title>
					<div>{{item.sellerEnterpriseName}}</div>
		        </template>
				<lida-table
					:pagination="false" 
					:height="300"
					:data="item.skuVOList"
					border
					ref="table">
						<lida-table-column label="产品信息" fixed>
							<template #default="scope">
								<div class="g-info">
									<div class="g-icon">
										<img :src="scope.row.src"/>
									</div>
									<div class="g-right">
										<div class="g-r-title">
											{{scope.row.productName}}
											<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
										</div>
										<div class="g-r-data">
											{{scope.row.barCode || '无'}}
											<span>|</span>
											{{scope.row.seriesName || '无'}}
											<span>|</span>
											{{scope.row.skuNumber || '无'}}
										</div>
									</div>
								</div>
							</template>
						</lida-table-column>
						<lida-table-column label="订货号" prop="articleNo" />
						<lida-table-column label="面价" prop="guidePrice">
							<template #default="scope">
								<div>￥{{scope.row.guidePrice}}</div>
							</template>
						</lida-table-column>
						<lida-table-column label="报价价格" prop="purchasePrice" v-if="item.sellerId === $store?.state?.userInfo?.userId">
							<template #default="scope">
								<div>￥{{scope.row.purchasePrice}}</div>
							</template>
						</lida-table-column>
						<lida-table-column label="报价数量" prop="qty"/>
						<lida-table-column label="销售价格">
							<template #default="scope">
								<div>￥{{scope.row.salesPrice}}</div>
							</template>
						</lida-table-column>
						<lida-table-column label="发货地址">
							<template #default="scope">
								<div>{{scope.row.shipArea}}</div>
							</template>
						</lida-table-column>
						<lida-table-column label="渠道">
							<template #default="scope">
								<div>{{scope.row.channel}}</div>
							</template>
						</lida-table-column>
						<lida-table-column label="是否过保" prop="isExpire" />
				</lida-table>
		      </el-collapse-item>
		</el-collapse>
	</div>
	<!-- 添加到采购购物车弹框 -->
	<add-cart-change-num 
		v-model="changeNumShow"
		@success="addSuccess"
		:info="activeRow" />
	<!-- 采购购物车 -->
	<add-purchase-order 
		v-model="purchaseCartShow"
		:data="cartGoods"
		@delete="deleteCart"
		@success="createSuccess"
		:inquiryId="inquiryId"
	/>
</template>

<script>
	import offerProfitCompute from "@/common/js/offerProfitCompute.js"
	//import purchaseCartMixins from "../../mixins/purchaseCart.js"
	export default{
		data(){
			return{
				data:[],
			}
		},
		props:['info','inquiryId'],
		//mixins:[purchaseCartMixins],
		methods:{
			addCart(row,info){ //加入询价单
				info.inquiryId = this.inquiryId;
				this.activeRow = {row,info};
				this.changeNumShow = true;
			},
			init(){
				var data = JSON.parse(JSON.stringify(this.info.forEnterprise || []));
				data.map(item => {
					item.skuVOList.map(son => {
						son.numType = 'qty';
						return offerProfitCompute(son);
					})
				})
				this.data = data;
			}
		},
		created(){
			this.init();
		},
		watch:{
			info(){
				this.init();
			}
		}
	}
</script>

<style scoped lang="less">
	.o{
		height: 100%;
		overflow-y: auto;
		.o-title{
			padding: 10px 20px 10px 0;
			display: flex;
			justify-content: space-between;
			span{
				color: #D84146;
				font-weight: bold;
				margin: 0 5px;
			}
		}
	}
	.m-r-cart{
		display: flex;
		justify-content: flex-end;
		box-sizing: border-box;
		.c-cart-icon{
			position: relative;
			cursor: pointer;
			i{
				font-size: 30px;
				color: @dh-color;
			}
			.dot{
				position: absolute;
				right: 0;
				top: -6px;
				border-radius: 8px;
				transform: translateX(50%);
				border: 2px solid #FFFFFF;
				p{
					padding: 0px 4px;
					font-size: 8px;
					color: #FFFFFF;
					background: @dh-color;
					border-radius: 8px;
				}
			}
		}
	}
</style>
