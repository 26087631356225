<template>
	<el-dialog
	    width="1000px"
		@open="open"
		top="6vh"
	  >
		<div class="a">
			<div class="a-title">发货</div>
			<div class="a-content">
				<lida-table
					:pagination="false" 
					:height="300" 
					:data="data"
					border
					@selection-change="selectGoods"
					ref="table">
						<el-table-column :selectable="checkSelectable" type="selection" width="55" fixed/>
						<lida-table-column label="产品信息" fixed>
							<template #default="scope">
								<div class="g-info">
									<div class="g-icon">
										<img :src="scope.row.src"/>
									</div>
									<div class="g-right">
										<div class="g-r-title">
											{{scope.row.productName}}
											<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
										</div>
										<div class="g-r-data">
											{{scope.row.barCode || '无'}}
											<span>|</span>
											{{scope.row.seriesName || '无'}}
											<span>|</span>
											{{scope.row.skuNumber || '无'}}
										</div>
									</div>
								</div>
							</template>
						</lida-table-column>
						<lida-table-column label="订货号" prop="articleNo" />
						<lida-table-column label="采购数量" :min-width="80" prop="purchaseQty" />
						<lida-table-column label="采购单价" :min-width="80" prop="purchasePrice" />
						<lida-table-column label="备注" :min-width="150" prop="remarks" />
						<lida-table-column label="数量">
							<template #default="scope">
								<el-input-number 
									v-model="scope.row.num" 
									:min="scope.row.weiNum >= 1 ? 1 : 0" 
									:max="scope.row.weiNum" 
									@change="handleChange"
									:disabled="!scope.row.weiNum"
								/>
							</template>
						</lida-table-column>
						<lida-table-column label="未发货数量" prop="weiNum" fixed="right" />
				</lida-table>
				<div class="c-buttom">
					<div class="c-left">
						<div class="c-item">
							<span><i>*</i>快递公司</span>
							<el-select style="flex: 1;" v-model="obj.courierCompany" filterable placeholder="请选择快递公司">
							    <el-option
							      v-for="item in courierCompany"
							      :key="item.type"
							      :label="item.title"
							      :value="item.type"
							    >
							    </el-option>
							  </el-select>
						</div>
						<div class="c-item" v-if="obj.courierCompany === 'shunfeng'">
							<span><i>*</i>手机号</span>
							<el-input style="flex: 1;" v-model="obj.courierPhone" placeholder="请输入快递单号" />
						</div>
						<div class="c-item">
							<span><i>*</i>快递单号</span>
							<el-input style="flex: 1;" v-model="obj.courierNumber" placeholder="请输入快递单号" />
						</div>
						<div class="c-item">
							<span>备注</span>
							<el-input
							    v-model="obj.remarks"
							    :rows="4"
							    type="textarea"
							    placeholder="请输入备注"
								style="flex: 1;"
							/>
						</div>
					</div>
					<!-- <div class="c-right">
						<div class="c-item">
							<span>发货资料</span>
							<el-upload
							    class="avatar-uploader"
							    :action="getAction"
								:headers="getHeaders"
							    :show-file-list="false"
								:on-success="onSuccess"
							  >
								<div class="up-img">
									<img v-if="imageUrl" :src="imageUrl" class="avatar" />
									<p class="iconfont icon-jiahao" v-else></p>
								</div>
							</el-upload>
						</div>
					</div> -->
				</div>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" size="mini" type="primary" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { mapActions , mapState } from 'vuex'
	import { runNumber , getBase64 } from '@/common/js/common.js'
	import { ElMessage } from 'element-plus'
	import request from "@/common/js/request.js"
	import api from "@/common/js/api.js"
	export default{
		data(){
			return{
				data:[],  //发货商品
				obj:{
					courierNumber:'',  //快递单号
					remarks:'',  //备注
					fileIds:[],
					courierCompany:'',  //快递公司
					courierPhone:'', //手机号
				},
				imageUrl:'',  //图片地址
			}
		},
		props:{
			orderInfo:{
				type:Array,
				default:() => {
					return [];
				}
			},
			oid:{ //订单id
				type:String,
				default:'',
			}
		},
		computed:{
			...mapState([
				'token', //获取token
			]),
			...mapState('purchaseMudule',[
				'courierCompany',  //快递公司
			]),
			getAction(){ //上传文件地址
				return request.defaults.baseURL + api.uploadFile + '?fileType=CUSTOMER';
			},
			getHeaders(){ //请求头部
				return {
					'Authorization':this.token || null,
				}
			}
		},
		methods:{
			...mapActions('mysaleMudule',[
				'purchaseList',  //获取发货商品列表
				'purchasePurchaseShip',  //发货
			]),
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			open(){
				//this.data = this.orderInfo.documentItemResultVOList;
				this.getList();
			},
			getList(){
				this.purchaseList(this.oid).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						data.map(item => {
							item.weiNum = runNumber(item.purchaseQty) - runNumber(item.shipQty);  //未发货数量
							item.weiNum = item.weiNum < 0 ? 0 : item.weiNum;
							item.num = item.weiNum >= 1 ? 1 : item.weiNum;
						})
						this.data = data;
					}
				})
			},
			checkSelectable(row){
				return !!row.weiNum;
			},
			selectGoods(data){
				this.data.map(item => {
					item.active = data.some(v => v.skuId === item.skuId);
				})
			},
			onSuccess(response, file, fileList){ //监听文件上传成功
				this.obj.fileIds = [response.data.fileId];  //图片id
				getBase64(file.raw).then(res => {
					this.imageUrl = res;
				})
			},
			confirm(){ //确认的点击事件
				var goods = JSON.parse(JSON.stringify(this.data.filter(v => v.active && v.num)));
				if(goods.length){
					if(!this.obj.courierNumber){
						return ElMessage({
							type:'warning',
							message:'请输入快递单号',
						});
					}else if(!this.obj.courierCompany){
						return ElMessage({
							type:'warning',
							message:'请选择快递公司',
						});
					}else if(this.obj.courierCompany === 'shunfeng'){
						if(!this.obj.courierPhone){
							return ElMessage({
								type:'warning',
								message:'请输入手机号',
							});
						}
					}
					var data = JSON.parse(JSON.stringify(this.obj)) ,
					arr = [];
					if(data.courierCompany !== 'shunfeng'){
						data.courierPhone = ''; //判断如果不是顺丰不带手机号
					}
					data.documentId = this.oid;
					goods.map(item => {
						arr.push({ skuId:item.skuId, shipQty:item.num})
					})
					data.shipItemList = arr;
					this.purchasePurchaseShip(data).then(res => {
						let {code,data} = res.data;
						if(code === "0"){
							ElMessage({
								type:'success',
								message:'发货成功',
							})
							this.$emit('success');
							this.clearData();
							this.close();
						}
					})
				}else{
					ElMessage({
						type:'warning',
						message:'请选择发货商品',
					})
				}
			},
			clearData(){ //清空数据
				this.data = [];  //发货商品
				this.obj = {
					courierNumber:'',  //快递单号
					remarks:'',  //备注
					fileIds:[],
				};
				this.imageUrl = '';
			}
		},
		created(){
			
		}
	}
</script>

<style scoped lang="less">
	.a{
		font-size: 14px;
		padding: 20px;
		.a-title{
			font-weight: bold;
			padding: 0 20px 20px 0;
		}
		.a-footer{
			display: flex;
			justify-content: center;
			padding-top: 20px;
		}
		.c-buttom{
			display: flex;
			justify-content: space-between;
			.c-left{
				flex: 2;
			}
			.c-right{
				margin-left: 20px;
			}
		}
		.c-item{
			display: flex;
			padding-top: 20px;
			span{
				white-space: nowrap;
				width: 70px;
				text-align: right;
				margin-right: 20px;
				line-height: 40px;
				i{
					color: #D84146;
					margin-right: 5px;
				}
			}
		}
	}
	.up-img{
		width: 120px;
		height: 120px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FBFDFF;
		border-radius: 10px;
		border: 1px solid #eeeeee;
		overflow: hidden;
		p{
			font-size: 40px;
			color: #999999;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
</style>
