<template>
	<div class="o">
		<div class="o-title">
			<div class="o-t-left">总报价数量<span>{{company.length}}</span>个</div>
		</div>
		<lida-table
			:pagination="false"
			:cutHeight="0"
			:data="data"
			border
			ref="table">
				<lida-table-column label="产品信息" fixed>
					<template #default="scope">
						<div class="g-info">
							<div class="g-icon">
								<img :src="scope.row.src"/>
							</div>
							<div class="g-right">
								<div class="g-r-title">
									{{scope.row.productName}}
									<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
								</div>
								<div class="g-r-data">
									{{scope.row.barCode || '无'}}
									<span>|</span>
									{{scope.row.seriesName || '无'}}
									<span>|</span>
									{{scope.row.skuNumber || '无'}}
								</div>
							</div>
						</div>
					</template>
				</lida-table-column>
				<lida-table-column label="订货号" prop="articleNo" />
				<!-- <lida-table-column label="面价" prop="guidePrice" /> -->
				<lida-table-column label="数量" prop="skuQty" :min-width="60" />
				<!-- <lida-table-column label="备注" prop="" /> -->
				<lida-table-column label="最高价" prop="maxPurchasePrice">
						<template #default="scope">
							<div>￥{{scope.row.maxPurchasePrice}}</div>
						</template>
				</lida-table-column>
				<lida-table-column label="最低价" prop="minPurchasePrice">
						<template #default="scope">
							<div>￥{{scope.row.minPurchasePrice}}</div>
						</template>
				</lida-table-column>
				<lida-table-column :min-width="200" :label="item.sellerEnterpriseName" v-for="(item) in company" :key="item.sellerEnterpriseId">
					<template #default="scope">
						<div v-if="item[scope.row.skuId]">
							<div :class="[
									'c',
									getRowClass(item[scope.row.skuId].purchasePrice,scope.row.minPurchasePrice,scope.row.maxPurchasePrice)
								]">
								<!-- <div class="c-item"><span>采购单价:</span>￥{{item[scope.row.skuId].purchasePrice}}</div> -->
								<div class="c-item"><span>数量:</span>{{item[scope.row.skuId].qty}}</div>
								<div class="c-item"><span>渠道:</span>{{item[scope.row.skuId].channel}}</div>
								<!-- <div class="c-item"><span>利润点数:</span>{{item[scope.row.skuId].profitSite}}</div>
								<div class="c-item"><span>面价点数:</span>{{item[scope.row.skuId].guideSite}}</div>
								<div class="c-item"><span>面价:</span>{{item[scope.row.skuId].guidePrice}}</div>
								<div class="c-item"><span>采购折扣 ( % ):</span>{{item[scope.row.skuId].purchaseDis}}</div> -->
								<div class="c-item"><span>销售折扣 ( % ):</span>{{item[scope.row.skuId].salesDis}}</div>
								<div class="c-item"><span>销售价格:</span>￥{{item[scope.row.skuId].salesPrice}}</div>
								<!-- <div class="c-item"><span>实际利润:</span>{{item[scope.row.skuId].profitC}}</div> -->
								<!-- <div class="c-item c-btn">
									<i class="iconfont icon-gouwuche" @click="addCart(item[scope.row.skuId],scope.row)"></i>
								</div> -->
							</div>
						</div>
						<div v-else>
							<el-empty :image-size="50" description="未报价"></el-empty>
						</div>
					</template>
				</lida-table-column>
				<!-- <lida-table-column label="操作" fixed="right">
					<template #default="scope">
						<el-button size="mini" @click="deleteRow(scope.row)" type="text">移除</el-button>
					</template>
				</lida-table-column> -->
		</lida-table>
	</div>
	<!-- 添加到采购购物车弹框 -->
	<add-cart-change-num
		v-model="changeNumShow"
		@success="addSuccess"
		:info="activeRow" />
	<!-- 采购购物车 -->
	<add-purchase-order
		v-model="purchaseCartShow"
		:data="cartGoods"
		@delete="deleteCart"
		@success="createSuccess"
		:inquiryId="inquiryId"
	/>
</template>

<script>
	import offerProfitCompute from "@/common/js/offerProfitCompute.js"
	//import purchaseCartMixins from "../../mixins/purchaseCart.js"
	export default{
		data(){
			return{
				data:[],  //表格数据
				company:[],
			}
		},
		props:['info','inquiryId'],
		//mixins:[purchaseCartMixins],
		watch:{
			info(v){
				this.data = v.forEnterprise || [];
			}
		},
		methods:{
			getRowClass(p1,p2,p3){ //
				return p3 === p1 ? 'minPrice' : p2 === p1 ? 'maxPrice' : '';
			},
			addCart(row,info){ //加入询价单
				console.log(row);
				console.log(info);
				info.inquiryId = this.inquiryId;
				this.activeRow = {row,info};
				this.changeNumShow = true;
			},
			init(){
				var data = JSON.parse(JSON.stringify(this.info.forSku || [])),
				company = [];
				//组装数据
				data.map(item => {
					item.enterpriseVOList.map(v => {
						var obj = {  //商品报价信息
								qty:v.qty,
								purchasePrice:v.purchasePrice,
								profitSite:v.profitSite,
								isNewest:v.isNewest,
								guideSite:v.guideSite,
								channel:v.channel,
								guidePrice:v.guidePrice,
								purchasePrice:v.purchasePrice,
								salesDis:v.salesDis,
								salesPrice:v.salesPrice,
								purchaseDis:v.purchaseDis,
								brandId:item.brandId,
								skuId:item.skuId,
								itemId:item.itemId,
						};
						obj.numType = 'qty';
						obj = offerProfitCompute(obj);  //计算后的价格
						if(company.some(i => i.sellerEnterpriseId === v.sellerEnterpriseId)){
							company[company.findIndex(i => i.sellerEnterpriseId === v.sellerEnterpriseId)][item.skuId] = obj;
						}else{
							company.push({
								sellerEnterpriseId:v.sellerEnterpriseId,
								sellerEnterpriseName:v.sellerEnterpriseName,
								sellerId:v.sellerId,
								sellerName:v.sellerName,
								purchasePrice:v.purchasePrice,
								[item.skuId]:obj,
							})
						}
					})
				})
				this.data = data;
				this.company = company;
			}
		},
		created(){
			this.init();
		},
		watch:{
			info(){
				this.init();
			}
		}
	}
</script>

<style scoped lang="less">
	.o{
		height: 100%;
		overflow-y: auto;
		.o-title{
			padding: 10px 20px 10px 0;
			display: flex;
			justify-content: space-between;
			span{
				color: #D84146;
				font-weight: bold;
				margin: 0 5px;
			}
		}
	}
	.c{
		box-sizing: border-box;
		padding: 10px;
		.c-item{
			text-align: center;
			span{
				margin-right: 5px;
			}
		}
		.c-btn{
			display: flex;
			justify-content: flex-end;
			i{
				font-size: 24px;
				color: #D84146;
				cursor: pointer;
			}
		}
	}
	.minPrice{
		background: #57CC66;
		color: #FFFFFF;
	}
	.maxPrice{
		background: #3797D3;
		color: #FFFFFF;
	}
	.m-r-cart{
		display: flex;
		justify-content: flex-end;
		box-sizing: border-box;
		.c-cart-icon{
			position: relative;
			cursor: pointer;
			i{
				font-size: 30px;
				color: @dh-color;
			}
			.dot{
				position: absolute;
				right: 0;
				top: -6px;
				border-radius: 8px;
				transform: translateX(50%);
				border: 2px solid #FFFFFF;
				p{
					padding: 0px 4px;
					font-size: 8px;
					color: #FFFFFF;
					background: @dh-color;
					border-radius: 8px;
				}
			}
		}
	}
</style>
