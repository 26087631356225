<template>
	<div class="e-header">
		<el-button type="success" size="mini" @click="exportInquiry">导出询价单</el-button>
	</div>
	<lida-table
		:pagination="false" 
		:cutHeight="0" 
		:data="data"
		border
		ref="table">
			<lida-table-column label="产品信息" fixed>
				<template #default="scope">
					<div class="g-info">
						<div class="g-icon">
							<img :src="scope.row.src"/>
						</div>
						<div class="g-right">
							<div class="g-r-title">
								{{scope.row.productName}}
								<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
							</div>
							<div class="g-r-data">
								{{scope.row.barCode || '无'}}
								<span>|</span>
								{{scope.row.seriesName || '无'}}
								<span>|</span>
								{{scope.row.skuNumber || '无'}}
							</div>
						</div>
					</div>
				</template>
			</lida-table-column>
			<lida-table-column label="订货号" prop="articleNo" />
			<lida-table-column label="面价" prop="guidePrice" />
			<lida-table-column label="报价数量" prop="quoteQty" />
			<lida-table-column label="采购数量" prop="purchaseQty" />
			<lida-table-column label="采购单价" prop="purchasePrice" />
			<lida-table-column label="发货地区" prop="shipArea" />
			<lida-table-column label="渠道" prop="channel" />
			<lida-table-column label="是否过保" prop="isExpire" />
	</lida-table>
</template>

<script>
	import exportExcel from '@/common/js/exportExcel.js'  //导出数据
	export default{
		data(){
			return{
				data:[],  //采购列表数据
			}
		},
		props:['info','orderInfo','inquiryId'],
		methods:{
			exportInquiry(){ //导出询价单
				var arr = [];
				this.data.map(item => {
					arr.push({
						'产品信息':item.productName + '|' + item.brandName + '1' +  item.barCode + '|' + item.seriesName + '|' + item.skuNumber,
						'订货号':item.articleNo,
						'面价':item.guidePrice,
						'生产日期':item.manufactureDate,
						'渠道':item.channel,
						'采购单价':item.purchasePrice,
						'利润点数 ( % )':item.profitSite,
						'面价点数 ( % )':item.guideSite,
						'数量':item.qty,
					})
				})
				exportExcel(arr,'询价单');
			},
		},
		created(){
			this.data = this.orderInfo.documentItemResultVOList;
		},
		watch:{
			orderInfo(v){
				this.data = v.documentItemResultVOList;
			}
		}
	}
</script>

<style scoped lang="less">
	.e-header{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-bottom: 20px;
	}
</style>
