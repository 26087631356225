<template>
	<div class="ed">
		<div class="ed-title">
			<div>
				{{orderInfo.documentState}}
			</div>
			<el-button
				size="mini"
				type="primary"
				v-if="orderInfo.documentStatus === 'WAIT_CONTRACT'"
				@click="uploadContract">上传合同</el-button>
			<el-button
				size="mini"
				type="primary"
				v-if="orderInfo.documentStatus === 'SHIP_PENDING' && orderInfo.isPay === '1'"
				@click="sendGoogd">发货</el-button>
			<el-button
				size="mini"
				type="primary"
				v-if="orderInfo.documentStatus === 'SHIP_PENDING' && orderInfo.isPay === '0'"
				@click="collection">收款</el-button>
		</div>
		<div class="ed-info">
			<div class="ed-info-i">
				<span>询价人:</span>
				<p>{{info.inquiryRequestVO.buyerEnterpriseName}}/{{info.inquiryRequestVO.buyerName}}/{{info.inquiryRequestVO.buyerPhone}}</p>
			</div>
			<div class="ed-info-i">
				<span>询价单号:</span>
				<p>{{info.inquiryRequestVO.inquiryNo}}</p>
			</div>
			<div class="ed-info-i">
				<span>制单时间:</span>
				<p>{{info.inquiryRequestVO.documentTime}}</p>
			</div>
		</div>
		<div class="line"></div>
		<el-tabs v-model="activeNav">
			<el-tab-pane
				:label="item.title"
				:name="item.type"
				:key="item.type"
				:disabled="item.disabled"
				v-for="item in navs">
			</el-tab-pane>
		</el-tabs>
		<div class="ed-content">
			<component
				@bjSuccess="bjSuccess"
				:info="info"
				:inquiryId="$route.query.inquiryId"
				:orderInfo="orderInfo"
				:is="comName"></component>
		</div>
	</div>

	<!-- 上传合同弹框 -->
	<upload-contract-dialog
		v-model="contractShow"
		title="上传合同"
		@success="contractSuccess"
	/>

	<!-- 发货弹框 -->
	<send-googd-dialog
		v-model="sendGoogdShow"
		:orderInfo="orderInfo"
		:oid="$route.query.id"
		@success="sendSuccess"
	/>

	<!-- 待收款弹框 -->
	<collectionDialog
		v-model="collectionShow"
		:orderInfo="orderInfo"
		:oid="$route.query.id"
		@success="sendSuccess"
	/>

</template>

<script>
	import productInfo from "../components/orderDetails/productInfo.vue"  //产品信息
	import inquiryInfo from "../components/orderDetails/inquiryInfo.vue"  //询价信息
	import offerInfo1 from "../components/orderDetails/offerInfo1.vue"  //报价信息1（指定企业报价）
	import offerInfo2 from "../components/orderDetails/offerInfo2.vue"  //报价信息2（全平台报价）
	import purchaseInfo from "../components/orderDetails/purchaseInfo.vue"  //采购信息
	import uploadContractDialog from "../components/dialog/uploadContract.vue"  //上传合同弹框
	import contractInfo from "../components/orderDetails/contractInfo.vue"  //合同信息
	import sendGoodsInfo from '../components/orderDetails/sendGoodsInfo.vue'  //发货信息
	import sendGoogdDialog from '../components/dialog/sendGoogd.vue'  //发货弹框
	import collectionDialog from '../components/dialog/collection.vue'  //待收款弹框
	import receipt from '../components/orderDetails/receipt.vue'  //收款凭证
	import { mapActions, mapState } from "vuex"
	import { ElMessage } from 'element-plus'
	import moment from 'moment'
	export default{
		data(){
			return{
				navs:[
          {title:'询价信息',type:4,name:'inquiryInfo'},
          {title:'产品信息',type:3,name:'productInfo'},
          {title:'报价信息',type:2,name:'offerInfo1'},
					{title:'采购信息',type:1,name:'purchaseInfo'},
				],
				activeNav:4,
				info:{
					inquiryRequestVO:{
						address:{},
					}
				},
				type:'my',  //my我的询价详情 customer 客户询价详情(默认我的询价详情)
				orderInfo:{}, //订单信息
				contractShow:false, //上传合同弹框的隐藏显示
				sendGoogdShow:false,  //发货弹框的隐藏显示
				collectionShow:false,  //待收款弹框
				payVoucherShow:false, //上传支付凭证的隐藏显示
			}
		},
		components:{
			productInfo,
			inquiryInfo,
			offerInfo1,
			offerInfo2,
			purchaseInfo,
			uploadContractDialog,
			contractInfo,
			sendGoogdDialog,
			sendGoodsInfo,
			collectionDialog,
			receipt,
		},
		methods:{
			...mapActions('purchaseMudule',[
				'inquiryInfo',  //获取询价详情
			]),
			...mapActions('mysaleMudule',[
				'purchaseDetails',  //获取订单详情
				'purchaseUpdateContractFile',  //上传合同
			]),
			getDetails(){ //获取
				this.inquiryInfo({
					inquiryId:this.$route.query.inquiryId
				}).then(res => {
					let {code,data} = res.data;
					if(code === "0"){
						//data.inquiryRequestVO.documentTime = moment(data.inquiryRequestVO.documentTime).format('YYYY.MM.DD hh:mm:ss');
						data.inquiryRequestVO.priceUnit = this.getType('moneys',data.inquiryRequestVO.priceUnit);
						data.inquiryRequestVO.projectFile = JSON.parse(data.inquiryRequestVO.projectFile || '[]');
						data.inquiryRequestVO.file = JSON.parse(data.inquiryRequestVO.file || '[]');
						data.inquiryRequestVO.documentText = this.documentState[data.inquiryRequestVO.documentState];
						data.inquiryRequestVO.paymentType = this.getType('paymentTypes',data.inquiryRequestVO.paymentType);
						data.inquiryRequestVO.projectState = data.inquiryRequestVO.projectState ?
							this.getType('projectState',data.inquiryRequestVO.projectState) : '';
						if((data.forEnterprise || data.forSku) || this.type === 'customer'){
							if(data.inquiryRequestVO.isPlatform === '1'){  //全平台报价
								this.navs[this.matchingIndex(2)].name = 'offerInfo2'
							}else{  //指定企业报价
								this.navs[this.matchingIndex(2)].name = 'offerInfo1'
							}
						}
						this.info = data; //商品列表
					}
				})
			},
			getType(attribute,type){
				return type ? attribute === 'paymentTypes' && !this[attribute].filter(v => v.type === type).length ?
				this[attribute][0].title : this[attribute].filter(v => v.type === type).length ?
				this[attribute].filter(v => v.type === type)[0].title : '' : '';
			},
			bjSuccess(){ //报价成功刷新页面信息
				this.getDetails();
				this.getOrderInfo();
			},
			getOrderInfo(){ //获取订单详情
				this.purchaseDetails(this.$route.query.id).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						data.documentState = this.documentState[data.documentStatus];
						data.contractFiles = JSON.parse(data.contractFiles || '[]');
						data.backContractFiles = JSON.parse(data.backContractFiles || '[]')
						data.payFiles = JSON.parse(data.payFiles || '[]')
						if((data.contractFiles && data.contractFiles.length) || (data.backContractFiles && data.backContractFiles.length)){
							if(!this.navs.some(v => v.type === 5)){
								this.navs.push({title:'合同信息',type:5,name:'contractInfo'});
								this.activeNav = 5;
							}
						}
						if(
							data.payFiles &&
							data.payFiles.length
						){
							if(!this.navs.some(v => v.type === 7)){
								this.navs.push({title:'收款凭证',type:7,name:'receipt'});
								this.activeNav = 7;
							}
						}
						if(
							data.purchaseShipResultVOList &&
							data.purchaseShipResultVOList.length
						){
							if(!this.navs.some(v => v.type === 6)){
								this.navs.push({title:'发货信息',type:6,name:'sendGoodsInfo'});
								this.activeNav = 6;
							}
						}
						this.orderInfo = data;
					}
				})
			},
			uploadContract(){ //上传合同
				this.contractShow = true;
			},
			contractSuccess(obj){  //上传合同成功的回调
				this.purchaseUpdateContractFile({
					remarks:obj.value,
					contractFileIds:obj.ids,
					documentId:this.$route.query.id,
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						ElMessage({
							type:'success',
							message:'上传成功',
						})
						//刷新详情数据
						this.getDetails();
						this.getOrderInfo();
						this.contractShow = false;
					}
				})
			},
			matchingIndex(type){
				return this.navs.findIndex(v => v.type === type);
			},
			sendGoogd(){ //发货
				this.sendGoogdShow = true;
			},
			sendSuccess(){ //发货成功
				this.getDetails();
				this.getOrderInfo();
			},
			collection(){ //收款
				this.collectionShow = true;
			},
		},
		computed:{
			...mapState('purchaseMudule',[
				'paymentTypes',
				'protects',
				'moneys',
				'documentState',
				'projectState',
			]),
			comName(){ //组件名字
				return this.navs.filter(v => this.activeNav === v.type)[0].name;
			},
		},
		created(){
			if(this.$route.query.type){
				this.type = this.$route.query.type;
			}
			this.getDetails();
			this.getOrderInfo();
		},
		watch:{
			activeNav(){
				console.log(this.navs);
			}
		}
	}
</script>

<style scoped lang="less">
	.ed{
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		.ed-info{
			display: flex;
			padding-bottom: 15px;
			.ed-info-i{
				font-size: 14px;
				display: flex;
				flex: 1;
				span{
					color: #999999;
					width: 80px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				p{
					flex: 1;
					padding-right: 40px;
				}
			}
		}
		.ed-title{
			padding-bottom: 5px;
			color: @dh-color;
			font-weight: bold;
			line-height: 30px;
			display: flex;
			justify-content: space-between;
		}
		.line{
			height: 15px;
			background: #F2F5FA;
			width: calc(100% + 30px);
			position: relative;
			left: -15px;
		}
		.ed-content{
			flex: 1;
			overflow-y: auto;
		}
	}
</style>
