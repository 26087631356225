<template>
	<el-dialog
	    width="1100px"
	  >
		<div class="a">
			<div class="a-title">待收款</div>
			<div class="a-content">
				<div class="left">
					<el-form
					    ref="ruleForm"
					    :model="ruleForm"
					    status-icon
					    :rules="rules"
					    label-width="120px"
					    class="demo-ruleForm"
					>
					    <el-form-item label="应收金额:">
							<div>￥{{getPrice}}</div>
					    </el-form-item>
					    <el-form-item label="收获币种:">
							<el-select v-model="ruleForm.priceUnit" style="width: 240px;">
								<el-option 
									:label="item.title" 
									:value="item.type" 
									v-for="item in moneys"
									:key="item.type"></el-option>
							</el-select>
					    </el-form-item>
					    <el-form-item label="收款金额:" prop="paidAmount">
					    	<el-input v-model="ruleForm.paidAmount" style="width: 240px;"></el-input>
							<p style="margin-left: 10px;">收款占比：{{percentage}}%</p>
					    </el-form-item>
						<el-form-item label="未收金额:">
							<el-input v-model="ruleForm.unpaidPrice" style="width: 240px;" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="备注:">
							<el-input :rows="4" v-model="ruleForm.remarks" type="textarea" style="width: 350px;"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<div class="imgs">
						<div class="title">卖家收款凭证</div>
						<el-image
							:src="img"
							style="width: 200px;height: 200px;object-fit: cover;"
							></el-image>
					</div>
					<div class="a-c-files">
						<div class="title">支付凭证<span>（最多上传5张）</span></div>
						<upload-file
							isType='add'
							list-type="picture-card"
							v-model="ids"
							@usuccess="upSuccess"
							@delete="upSuccess"
							:limit="5"
							:list="fileList"
						/>
					</div>
				</div>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" size="mini" type="primary" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { mapState , mapActions } from 'vuex'
	import { runNumber } from '@/common/js/common.js'
	import { ElMessage } from 'element-plus'
	import uploadFile from '@/components/publicComponent/uploadFile'
	export default{
		data(){
			return{
				ruleForm:{
					priceUnit:'CNY',  //币种
					paidAmount:'',  //收款金额
					unpaidPrice:'',  //未收金额
					type:'1',
					remarks:'',  //备注
				},
				percentage:'0.00',
				rules:{
					paidAmount:[
						{
							required: true,
							message: '请输入收款金额',
							trigger: 'blur',
						}
					]
				},
				ids:[],
				fileList:[],
				img:'',
			}
		},
		components:{
			uploadFile,
		},
		props:['orderInfo','oid','files'],
		computed:{
			...mapState('purchaseMudule',[
				'moneys',  //币种
			]),
			getPrice(){ //应收金额
				var list = this.orderInfo && this.orderInfo.documentItemResultVOList ? this.orderInfo.documentItemResultVOList : [] ,
				price = 0;
				list = JSON.parse(JSON.stringify(list));
				list.map(item => {
					price += runNumber(item.purchasePrice) *  runNumber(item.purchaseQty);
				})
				price -= runNumber(this.orderInfo.paidAmount);
				return price.toFixed(2);
			},
		},
		methods:{
			...mapActions('mysaleMudule',[
				'purchaseCollection',  //采购单收款
				'userGetUserSet',  //获取收款二维码
			]),
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
				this.$emit('close');
			},
			confirm(){ //确认的点击事件
				this.$refs.ruleForm.validate((valid) => {
					if(valid){
						var data = JSON.parse(JSON.stringify(this.ruleForm));
						delete data.unpaidPrice;
						data.documentId = this.oid;
						this.purchaseCollection(data).then(res => {
							let {code,data} = res.data;
							if(code === '0'){
								ElMessage({
									type:'success',
									message:'操作成功',
								})
								this.close();
								this.$emit('success');
							}
						})
					}
				})
			},
			upSuccess(){
				this.$nextTick(() => {
					this.$emit('upSuccess',this.ids);
				})
			},
			getCode(){
				this.userGetUserSet({
					setTpCd:'COLLECTION_QR_CODE',
					sellerId:this.orderInfo.sellerId,
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						if(data && data.files){
							data.files = JSON.parse(data.files);
							if(data.files.length){
								this.img = data.files[0].url;
							}
						}
					}
				})
			},
		},
		created(){
			this.getCode();
		},
		watch:{
			'ruleForm.paidAmount'(v){
				var percentage = runNumber(runNumber(v) / runNumber(this.getPrice) * 100);
				var unpaidPrice = runNumber(this.getPrice) - runNumber(v);
				unpaidPrice = unpaidPrice > 0 ? unpaidPrice : 0;
				this.ruleForm.unpaidPrice = unpaidPrice.toFixed(2);
				this.percentage = percentage.toFixed(2);
			},
			files(v){
				this.fileList = v;
			}
		}
	}
</script>

<style scoped lang="less">
	.a{
		padding: 20px;
		.a-title{
			padding-bottom: 20px;
			font-weight: bold;
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;
		}
		.a-content{
			padding: 0 20px;
			display: flex;
			.right{
				flex: 1;
				padding-left: 50px;
				.title{
					width: 100%;
					line-height: 30px;	
					font-weight: bold;
					span{
						color: #999999;
						font-weight: normal;
					}
				}
				.a-c-files{
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
		/deep/ .el-form-item{
			margin-bottom: 15px;
		}
		/deep/ .el-form-item__content{
			display: flex;
			align-items: center;
		}
	}
</style>
