<template>
	<div class="i">
		<el-row>
			<el-col :span="8">
				<span>固定电话:</span>
				<p>{{info.inquiryRequestVO.buyerPhone}}</p>
			</el-col>
			<el-col :span="8">
				<span>币种选择:</span>
				<p>{{info.inquiryRequestVO.priceUnit}}</p>
			</el-col>
			<el-col :span="8">
				<span>付款方式:</span>
				<p>{{info.inquiryRequestVO.paymentType}}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="8">
				<span>项目名称:</span>
				<p>{{ isProjectType ? info.inquiryRequestVO.projectName : '一般项目'}}</p>
			</el-col>
			<el-col :span="8" v-if="isProjectType">
				<span>项目状态:</span>
				<p>{{info.inquiryRequestVO.projectState}}</p>
			</el-col>
			<el-col :span="8" v-if="isProjectType">
				<span>项目地址:</span>
				<p>{{info.inquiryRequestVO.projectAddr}}</p>
			</el-col>
		</el-row>
		<el-row v-if="isProjectType">
			<el-col :span="8">
				<span>厂家联系人：</span>
				<p>{{info.inquiryRequestVO.contactName}}</p>
			</el-col>
			<el-col :span="8">
				<span>厂家联系电话：</span>
				<p>{{info.inquiryRequestVO.contactPhone}}</p>
			</el-col>
		</el-row>
		<el-row v-if="isProjectType">
			<el-col :span="24">
				<span>项目图纸</span>
				<div class="p">
					<el-image
						:src="getFile.url"
						style="width: 300px;height: 180px;object-fit: cover;"
						v-if="isFileType"
					/>
					<div class="c-item">
						<span @click="open(getFile)">{{getFile.fileName}}</span>
						<el-button @click="download(getFile)" type="primary" size="mini">下载</el-button>
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="i-title">收货人信息</div>
		<el-row>
			<el-col :span="8">
				<span>收货人:</span>
				<p>{{info.inquiryRequestVO?.address?.consigneeName}}</p>
			</el-col>
			<el-col :span="8">
				<span>联系电话:</span>
				<p>{{info.inquiryRequestVO?.address?.consigneePhone}}</p>
			</el-col>
			<el-col :span="8">
				<span>所在区域:</span>
				<p>{{getArea}}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<span>详细地址:</span>
				<p>{{info.inquiryRequestVO?.address?.address}}</p>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { fileIsImage , downLoadFile } from '@/common/js/common.js' 
	export default{
		data(){
			return{
				
			}
		},
		props:['info'],
		computed:{
			getArea(){ //获取地址信息
				return this.info.inquiryRequestVO?.address?.province + ' - ' +
					this.info.inquiryRequestVO?.address?.city + ' - ' +
					this.info.inquiryRequestVO?.address?.district;
			},
			getFile(){ //图片路径
				return this.info.inquiryRequestVO.projectFile && 
				this.info.inquiryRequestVO.projectFile.length ? this.info.inquiryRequestVO.projectFile[0] : {url:'',fileName:''};
			},
			isFileType(){ //判断文件类型
				return fileIsImage(this.getFile.url);
			},
			isProjectType(context,type){ //判断是不是一搬项目
				return this.info.inquiryRequestVO.projectType === '1' || this.info.inquiryRequestVO.projectType === '2';
			}
		},
		methods:{
			download(row){ //下载
				downLoadFile(row.url,row.fileName);
			},
			open(row){ //直接打开
				window.open(row.url);
			}
		},
		watch:{
			info(v){
				console.log(v);
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/ .el-row{
		padding:15px 0;
		.el-col{
			font-size: 14px;
			display: flex;
			align-items: center;
			span{
				color: #999999;
				width: 80px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			p{
				flex: 1;
			}
		}
	}
	.i-title{
		color: @dh-color;
		font-size: 14px;
		font-weight: bold;
		padding-top: 10px;
	}
	.c-item{
		display: flex;
		align-items: center;
		padding: 10px 0;
		span{
			color: #0A25FF;
			margin-right: 10px;
			text-decoration: underline;
			cursor: pointer;
			width: inherit !important;
		}
	}
</style>
